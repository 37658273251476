/**Created by liaoyingchao on 11/25/22.*/

<template>
  <div class="refund page-css list-css" v-loading="pageLoading">
    <div class="top-tools">
      <el-form ref="RefFilterForm" :model="form" label-width="90px">
        <div class="filter-items">
          <el-form-item class="filter-item" label="收货人姓名">
            <el-input v-model="form.receiverName" clearable placeholder="请输入收货人姓名"></el-input>
          </el-form-item>
          <el-form-item class="filter-item" label="收货人电话">
            <el-input v-model="form.memberPhone" clearable placeholder="请输入收货人电话"></el-input>
          </el-form-item>
          <el-form-item class="filter-item" label="退款状态">
            <el-select v-model="form.refundState" placeholder="请选择退款状态" style="width: 100%;" clearable>
              <el-option
                      v-for="item in refundOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div class="btns-div">
            <el-button type="primary" :icon="Search" @click="filterEvent">查询</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="full-container">
      <TableContainer ref="TabelContainer" :listApiFunction="getList">
        <template #default="tbData">
          <el-table
                  :height="tbData.data.tableHeight"
                  :data="tbData.data.tableData"
                  border
                  stripe
                  header-row-class-name="theader"
                  style="width: 100%">
            <el-table-column
                    prop="orderId"
                    label="订单号"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="receiverName"
                    label="收货人姓名"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    prop="memberPhone"
                    label="收货人电话"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    prop="paymentAmount"
                    label="支付金额(元)"
                    min-width="110">
            </el-table-column>
            <el-table-column
                    prop="refundAmount"
                    label="退款金额(元)"
                    min-width="110">
            </el-table-column>
            <el-table-column
                    prop="refundReason"
                    label="退款原因"
                    min-width="160">
            </el-table-column>
            <el-table-column
                    prop="remark"
                    label="拒绝原因"
                    min-width="160">
            </el-table-column>
            <el-table-column
                    prop="refundState"
                    label="退款状态"
                    min-width="100">
              <template #default="scope">
                {{ getRefundStateText(scope.row.refundState) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="createdTime"
                    label="申请时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.createdTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="orderAddress"
                    label="收货地址"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="orderState"
                    label="订单状态"
                    min-width="100">
              <template #default="scope">
                {{ getOrderStateText(scope.row.orderState) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="orderNumber"
                    label="商品数目"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="createdTime"
                    label="购买时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.orderCreatedTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="160">
              <template #default="scope">
                <div v-if="scope.row.refundState == 10">
                  <el-button @click="refundEvent(scope.row)" type="danger" text>退款</el-button>
                  <el-button @click="refuseEvent(scope.row)" type="warning" text>拒绝</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </TableContainer>
    </div>
    <el-dialog
            class="center-dialog"
            v-model="dialogVisible"
            title="拒绝退款理由"
            width="60%"
            destroy-on-close
    >
      <div class="dialog-content">
        <el-form ref="Ref_editForm" :model="editForm" :rules="formRules" label-width="120px">
          <el-form-item label="拒绝理由" prop="remark">
            <el-input v-model="editForm.remark" placeholder="请输入拒绝理由" maxlength="100"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEvent">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import {ref, reactive} from 'vue'
  import {
    Search,
    Plus,
  } from '@element-plus/icons-vue'
  import {ElMessage, ElMessageBox} from 'element-plus'
  import TableContainer from '@/components/table-container/index'
  import FormCheck from "@/utils/formCheck";
  import refundApi from '@/api/refund'
  import {timeYYYY_MM_DD_HH_mm_ss} from '@/utils/time-format.js'

  const pageLoading = ref(false)
  const RefFilterForm = ref(null)

  const form = reactive({
    receiverName: '',
    memberPhone: '',
    refundState: ''
  })

  function getList(data) {
    return refundApi.mgtList(data);
  }

  const stateOptions = ref([{
    label: '待支付',
    value: 10
  }, {
    label: '待配送',
    value: 20
  }, {
    label: '配送中',
    value: 30
  }, {
    label: '已完成',
    value: 40
  }, {
    label: '已取消',
    value: 50
  }, {
    label: '已退款',
    value: 60
  }])

  function getOrderStateText(state) {
    if (!state) {
      return ''
    }
    let statItem = stateOptions.value.find(item => {
      return item.value == state
    }) || {}

    return statItem.label || '';
  }

  const refundOptions = ref([{
    label: '申请中',
    value: 10
  }, {
    label: '拒绝退款',
    value: 20
  }, {
    label: '同意退款',
    value: 30
  }, {
    label: '完成退款',
    value: 40
  }])

  function getRefundStateText(state) {
    if (!state) {
      return ''
    }
    let statItem = refundOptions.value.find(item => {
      return item.value == state
    }) || {}

    return statItem.label || '';
  }

  const TabelContainer = ref(null)

  function filterEvent() {
    TabelContainer.value.filterEvent(form)
  }

  let dialogVisible = ref(false)

  const editForm = reactive({
    refundId: '',
    remark: ''
  })

  const formRules = {
    remark: FormCheck.required('请输入拒绝理由')
  }

  const Ref_editForm = ref(null)

  function saveEvent() {
    Ref_editForm.value.validate((valid, fields) => {
      if (valid) {
        console.log('submit!')

        pageLoading.value = true
        refundApi.refuse({
          refundId: editForm.refundId,
          remark: editForm.remark,
        }).then(res => {
          if (res.code == 0) {
            dialogVisible.value = false
            // filterEvent();
            TabelContainer.value.loadData()
          } else {
            ElMessage.error(res.msg);
          }
        }).finally(() => {
          pageLoading.value = false
        })
      } else {
        console.log('error submit!', fields)
      }
    })
  }

  function refuseEvent(item) {
    editForm.refundId = item.refundId
    editForm.remark = ''
    dialogVisible.value = true
  }

  function refundEvent(item) {
    ElMessageBox.confirm('点击确定按钮将会把订单支付金额退给客户，您确定需要退款给客户吗？', '退款提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      pageLoading.value = true
      refundApi.doRefund({
        refundId: item.refundId
      }).then(res => {
        if (res.code == 0) {
          dialogVisible.value = false
          // filterEvent();
          TabelContainer.value.loadData()
        } else {
          ElMessage.error(res.msg);
        }
      }).finally(() => {
        pageLoading.value = false
      })
    })
  }

</script>

<style lang="stylus" scoped type="text/stylus">
  .refund {
    >>> .dialog-content {
      max-height 65vh;
      overflow-y auto;
      padding-right 40px;
    }
  }
</style>