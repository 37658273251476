import http from '@/utils/http'

const order = {
  mgtList(data) {
    return http.post('/api/refund/mgtlist', data)
  },
  refuse(data) {
    return http.post('/api/refund/refuse', data)
  },
  doRefund(data) {
    return http.post('/api/refund/dorefund', data)
  },
}

export default order;